.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  list-style-type: none;
  padding: 0;
}

.addressInfo {
  margin: 20px 0;
}

.title {
  font-weight: 600;
  margin-bottom: 20px;
}

.span {
  font-weight: 600;
}

@media (max-width: 500px) {
  .container {
    grid-template-columns: 1fr;
  }
}