.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid grey;
  margin-bottom: 20px;
}

.container {
  position: relative;
  width: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  border: 0;
}

.span_qty {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border-radius: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: red;
  color: white;
  font-size: 12px;
  line-height: 18px;
  z-index: 1;
}

.section_languages {
  display: flex;
  gap: 5px;
}
