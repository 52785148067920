.delivery {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  margin-bottom: 32px;
}

.inputbox {
  display: flex;
  flex-wrap: nowrap;
}

.input {
  width: calc(100% - 79px);
}

.deliveryMethod {
  margin-bottom: 20px;
}

.map {
  height: 280px;
}
