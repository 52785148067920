.container {
  position: relative;
  padding: 20px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 280px;
  padding: 80px;
  background-color: #fff;
  border: 0.25px solid rgba(41, 41, 41, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  z-index: 3;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #292929;
  margin-bottom: 40px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin-bottom: 8px;
}

.number {
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 96px;
  color: #292929;
  margin-bottom: 40px;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: #3C39EC;
}

@media (max-width: 420px) {
  .modal {
    width: calc(100vw - 30px);
  }
}
