@import url(./fonts/fonts.css);

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'IBM Plex Sans', sans-serif;
  max-width: 720px;
  margin: 0 auto;
  padding: 40px 40px 48px;
}

p,
h1,
h2,
h3 {
  margin: 0;
}

@media (max-width: 720px) {
  body {
    padding: 30px 30px 48px;
  }
}

@media (max-width: 560px) {
  body {
    padding: 20px 20px 48px;
  }
}
