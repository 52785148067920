.section_homepage {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}

.items_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.info_container {
  display: flex;
  flex-direction: column;
}

.cart_image {
  width: 90px;
  opacity: .2;
  align-self: center;
}

.button_extraclass {
  margin-top: 10px;
}

.total_text {
  font-weight: bold;
  font-size: 1.5em;
}
