.huge {
  width: 150px;
  height: 150px;
}

.large {
  width: 40px;
  height: 40px;
}

.medium {
  width: 24px;
  height: 24px;
}

.small {
  width: 16px;
  height: 16px;
}

.wrapper_huge {
  width: 100%;
  min-height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrapper_large {
  width: 100%;
  min-height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrapper_medium {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrapper_small {
  width: 100%;
  min-height: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
