.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: 0 0 20px;
}

.options {
  margin: 0;
  padding: 0;
}