.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  box-shadow: inset 0px -1px 0px rgba(41, 41, 41, 0.13);
}

.option-checked{
  background: #FAFAFA;
  border-radius: 4px 4px 0px 0px;
}

.leftbox {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.img {
  width: 48px;
  height: 48px;
  margin-right: 20px;
}

.duration {
  margin-right: 20px;
}

.price {
  color: #3C39EC;
  margin-right: 20px;
}

.input {
  margin-right: 20px;
}

.input:checked,
.input:not(:checked)
{
  position: absolute;
  left: -9999px;
}

.input:checked + label,
.input:not(:checked) + label
{
  position: relative;
  padding-left: 40px;
  line-height: 20px;
  cursor: pointer;
}

.input:checked + label:before,
.input:not(:checked) + label:before
{
  content: "";
  position: absolute;
  left: 0px;
  top: 14px;
  width: 20px;
  height: 20px;
  border: 2px solid #3C39EC;
  background-color: #ffffff;
  border-radius: 100%;
}

.input:checked + label:after,
.input:not(:checked) + label:after
{
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  left: 8px;
  top: 22px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #3C39EC;
  border: 1px solid #3C39EC;
}

.input:not(:checked) + label:after {
    opacity: 0;
}

.input:checked + label:after {
    opacity: 1;
}

@media (max-width: 500px) {
  .option {
    flex-direction: column;
    align-items: flex-start;
  }

  .duration {
    margin-top: 20px;
    margin-left: 40px;
  }

  .price {
    margin-top: 20px;
    margin-left: 40px;
  }
}