.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: inset 0px -1px 0px rgba(41, 41, 41, 0.13);
  padding: 20px 0;
}

.img {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 20px;
  width: 80px;
  height: 80px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: 0;
  margin-right: 20px;
  max-width: 412px;
}

.leftbox {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  .product {
    flex-direction: column;
    align-items: flex-start;
  }

  .leftbox {
    flex-direction: column;
    align-items: flex-start;
  }

  .text {
    margin: 20px 0;
  }
}