.product {
  position: relative;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: inset 0px -1px 0px rgba(41, 41, 41, 0.13);
  padding: 20px 0;
}

.img {
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
  width: 80px;
  height: 80px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: 0;
  max-width: 220px;
}

.amountbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 128px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
}

.price {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-width: 74px;
}

.price {
  color: #3C39EC;
}

.exPrice {
  color: #292929;
  text-decoration: line-through;
}

.productBox {
  display: flex;
  gap: 20px;
  align-items: center;
}

.productBox::before {
  content: url('../../images/drag.svg');
  display: block;
  position: absolute;
  left: -24px;
  cursor: move;
}

.amount {
  margin: 0 10px;
}

.delete_button {
  border: none;
}

@media (max-width: 550px) {
  .product {
    flex-direction: column;
    align-items: flex-start;
  }

  .text {
    max-width: 100%;
    text-align: flex-start;
  }

  .amountbox {
    margin: 0;
    align-self: center;
  }

  .delete_button {
    margin-left: auto;
  }
}
