.article {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.img {
  width: 100%;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: 8px auto 4px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: 0 0 20px;
  min-height: 100px;
}

.button {
  width: 100%;
  border-radius: 4px;
  margin-top: auto;
}

.amountbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 128px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #292929;
  margin: auto auto 4px;
}

.amount {
  margin: 0 10px;
}
