.button {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #292929;
  background: #EBEBEB;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}

.close {
  width: 24px;
  height: 24px;
  margin-left: 8px;
}