.cart_section {
  display: flex;
  flex-direction: column;
}

.promo {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.promo_button {
  margin-right: 20px;
}

.input {
  max-width: 203px;
}

.text {
  margin: 8px auto 8px 0;
}

.button {
  margin-top: 20px;
  align-self: flex-end;
  border-radius: 4px;
}

.inputWithBtn {
  display: flex;
  align-items: center;
}

.hint {
  display: flex;
}

@media (max-width: 550px) {
  .promo {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .promocode {
    margin-top: 10px;
  }

  .input {
    width: calc(100% - 115px);
  }

  .promo_button {
    margin: 0;
  }
}
