.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #292929;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: right;
  color: #3C39EC;
}

.buttonbox {
  margin-top: 20px;
  align-self: flex-end;
}

.button {
  margin-right: 20px;
}

@media (max-width: 600px) {
  .buttonbox {
    display: flex;
    flex-direction: column;
  }

  .button {
    margin-right: 0;
    margin-bottom: 20px;
  }
}